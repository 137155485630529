<template>
  <div
    class="relative mt-12 h-20 w-full rounded border-[1px] border-grid shadow-grid"
  >
    <div class="absolute -top-3 -left-2.5">
      <img src="../images/footer_snow_left.svg" class="flex-shrink-0" />
    </div>
    <footer class="mx-auto h-full bg-tertiary/30">
      <div
        class="border-b-1 inset-x-0 flex h-full items-center justify-between px-2 font-medium md:px-6"
      >
        <div class="flex items-center space-x-4">
          <IconSnow size="h-5 w-5" />
          <div
            class="whitespace-nowrap border-l-[1px] border-blue-400/60 px-4 py-1"
          >
            <a href="https://thetie.io" target="_blank" class="h-10"
              ><img src="../images/the_tie_logo.svg" class="w-16 flex-shrink-0"
            /></a>
          </div>
          <div
            class="hidden items-center space-x-1 px-2 text-xs sm:flex sm:space-x-6 sm:px-6 sm:text-sm"
          >
            <div @click="openModal('privacy_policy')" class="cursor-pointer">
              Privacy Policy
            </div>
            <div @click="openModal('terms_of_use')" class="cursor-pointer">
              Terms of Use
            </div>
            <div
              @click="openModal('add_your_project')"
              class="cursor-pointer rounded-md bg-indigo-600 px-4 py-2 text-white hover:bg-indigo-700"
            >
              Add Your Project
            </div>
          </div>
        </div>
        <CoreButton
          class="w-26 shadow-gray/90 h-8 border-none bg-grid shadow-inner"
        />
      </div>
    </footer>
  </div>
  <Modal
    :modalType="modalType"
    :show-modal="privacyPolicyModal"
    @closeModal="closeModal()"
  />
</template>
<script setup>
import { Link } from '@inertiajs/inertia-vue3';
import CoreButton from './CoreButton.vue';
import IconSnow from '@/icons/IconSnow.vue';
import { ref } from 'vue';
import PrivacyPolicyModal from './PrivacyPolicyModal.vue';
import Modal from './Modal.vue';
function findCurrentUrl(url) {
  let currentUrl = url.split('/').filter((x) => x)[0];
  return currentUrl === undefined ? 'dashboard' : currentUrl;
}
const links = ref([
  {
    name: 'Dashboard',
    url: '/dashboard',
    activeTab: ['dashboard'],
  },
  {
    name: 'DeFi',
    url: '/defi',
    activeTab: ['defi'],
  },
  {
    name: 'Gaming',
    url: '/gaming',
    activeTab: ['gaming', 'projects', 'tokens'],
  },
  {
    name: 'NFT',
    url: '/nfts',
    activeTab: ['nfts'],
  },
]);

// PRIVACY POLICY
const privacyPolicyModal = ref(false);
const modalType = ref('');
function openModal(type) {
  modalType.value = type;
  privacyPolicyModal.value = true;
  document.body.classList.add('overflow-y-hidden');
}
function closeModal() {
  privacyPolicyModal.value = false;
  document.body.classList.remove('overflow-y-hidden');
}
</script>
