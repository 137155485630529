<template>
  <div
    class="bg-gradient-to-br from-[#01133d] via-[#02060b] to-[#02060b]"
    style="font-family: 'Inter', sans-serif"
  >
    <TopNav />
    <div
      id="app-wrapper"
      class="container relative mx-auto overflow-x-hidden py-6"
    >
      <NavBar />
      <slot></slot>
      <Footer />
    </div>
  </div>
</template>
<script setup>
import TopNav from './TopNav.vue';
import NavBar from './NavBar.vue';
import Footer from './Footer.vue';
import {
  ref,
  onMounted,
  provide,
  watch,
  readonly,
  computed,
  onBeforeMount,
  reactive,
} from 'vue';
import { ProjectApi } from '../Pages/Projects/ProjectApi';
const api = new ProjectApi();

const universeData = ref([]);
onBeforeMount(async () => {
  fetchTokens();
});

async function fetchTokens() {
  universeData.value = await api.fetchTokens('all');
}
setInterval(fetchTokens, 60000);

const tokens = computed(() => {
  return universeData.value?.tokens;
});
const projects = computed(() => {
  return universeData.value?.projects;
});
const nfts = computed(() => {
  return universeData.value?.nfts;
});
const topCollections = computed(() => {
  return universeData.value?.top_collections;
});
const defiTokens = computed(() => {
  return universeData.value?.defi_tokens;
});
const chill_factor = computed(() => {
  return universeData.value?.chill_factor;
});
provide('universeData', readonly(universeData));

provide('universeTokens', readonly(tokens));
provide('universeProjects', readonly(projects));
provide('universeNFTs', readonly(nfts));
provide('universeTopCollections', readonly(topCollections));
provide('universeDefiTokens', readonly(defiTokens));
provide('universeChillFactor', readonly(chill_factor));

const sharedState = reactive({ value: '' });
provide('sharedState', sharedState);

// TWITTER WIDGETS SCRIPT
window.twttr = (function (d, s, id) {
  var js,
    fjs = d.getElementsByTagName(s)[0],
    t = window.twttr || {};
  if (d.getElementById(id)) return t;
  js = d.createElement(s);
  js.id = id;
  js.src = 'https://platform.twitter.com/widgets.js';
  fjs.parentNode.insertBefore(js, fjs);

  t._e = [];
  t.ready = function (f) {
    t._e.push(f);
  };

  return t;
})(document, 'script', 'twitter-wjs');
</script>
