<template>
  <BaseCard class="h-[18rem] sm:h-full">
    <div :class="`${boxBg} rounded-2xl ${boxWidth} space-y-3 sm:space-y-0`">
      <div class="grid h-28 grid-cols-3 py-3">
        <div class="col-span-1 items-center">
          <!-- <div @click="nftClicked()" class="col-span-1 items-center"> -->
          <img
            v-if="imageUrl && available"
            :src="imageUrl"
            @error="available = false"
            class="left-0 h-full w-full rounded-lg object-cover transition duration-300 ease-in-out group-hover:scale-110"
            id="nft-box"
            loading="lazy"
          />
          <span
            v-else
            class="flex flex-col items-center justify-center text-center text-xs"
          >
            <PhotoIcon class="w-12" />
            <div>Image Not Found</div>
          </span>
        </div>
        <div class="col-span-2 w-full px-4 text-gray-100 sm:px-0 lg:px-4">
          <div
            class="items-center justify-between space-y-1 sm:flex"
            v-if="!hideName"
          >
            <p
              class="flex break-words text-gray-400 line-clamp-1 text-xs"
              :title="props.nft?.collection_name"
            >
              {{ props.nft?.collection_name }}
            </p>
          </div>
          <Link :href="`/nfts/${props.nft?.collection}`">
            <div
              class="items-center justify-between space-y-1 truncate sm:flex"
            >
              <span class="flex space-x-1 font-bold">
                <p
                  class="flex break-words line-clamp-1"
                  :title="props.nft?.collection_name"
                >
                  {{
                    props.nft?.collection_name.length > 7
                      ? props.nft?.collection_name.slice(0, 7) + '...'
                      : props.nft?.collection_name
                  }}
                </p>
                <span class="line-clamp-1">#{{ nft.token_id }}</span>
              </span>
              <span class="flex space-x-1" v-if="hideName">
                <IconAvalanche class="w-4 text-gray-500" />
                <span v-if="nft?.last_sold_for">{{
                  formatNumber(setupPrice(nft?.last_sold_for), 2)
                }}</span>
                <span class="text-gray-500" v-else>n/a</span>
              </span>
            </div>
            <span class="flex space-x-1 pt-2">
              <img src="../images/Avalanche_avax.svg" alt="Ava Currency Logo" />
              <span>{{ formatNumber(avaxPrice, 2) }}</span>
            </span>
          </Link>
        </div>
      </div>
      <div class="space-y-2 py-0 text-nftCardText text-xs sm:py-2">
        <div
          class="flex border-spacing-0.5 items-center justify-between border-b border-grid px-1"
        >
          <div class="w-1/2">Rarity</div>
          <div class="mb-1 flex w-1/2 pl-2 font-sourceCodePro">
            <span
              v-if="rarityRank"
              class="rounded p-1 px-2 font-medium text-white text-xs"
              :class="`${
                slugify(rarityRank, '') == 'rare'
                  ? 'bg-[#542188]'
                  : 'bg-[#24366E]'
              }`"
              >{{ rarityRank }}</span
            >
            <span v-else class="p-1"> - </span>
          </div>
        </div>
        <div
          class="flex items-center justify-between border-b border-grid px-1 pb-1"
        >
          <div class="w-1/2">ID Number</div>
          <div class="flex w-1/2 pl-2 font-sourceCodePro">
            #{{ nft?.token_id }}
          </div>
        </div>
        <div
          class="flex items-center justify-between border-b border-grid px-1 pb-1"
        >
          <div class="w-1/2">Last Price</div>
          <div class="flex w-1/2 pl-2 font-sourceCodePro">
            {{ formatNumber(avaxPrice, 2) + ' ' + 'AVAX' }}
          </div>
        </div>
        <div
          class="flex items-center justify-between border-b border-grid px-1 pb-1"
        >
          <div class="w-1/2">Volume Score</div>
          <div
            class="flex w-1/2 items-center pl-2 font-sourceCodePro text-xs lg:pl-1 xl:pl-2"
          >
            <span
              v-if="
                nft?.liquidity_score &&
                slugify(nft?.liquidity_score, '') == 'veryhigh'
              "
              class="pr-1 lg:pr-0 2xl:pr-1"
            >
              <ExclamationCircleIcon class="h-4 w-4"
            /></span>
            <div class="uppercase">
              {{ nft?.liquidity_score }}
            </div>
          </div>
        </div>
        <div
          class="flex items-center justify-between border-b border-grid px-1 pb-1"
        >
          <div class="text-purple-800/5- w-1/2">Ownership Risk</div>
          <div
            class="flex w-1/2 items-center pl-2 font-sourceCodePro uppercase text-xs lg:pl-1 xl:pl-2"
          >
            <span
              v-if="
                nft?.ownership_risk &&
                slugify(nft?.ownership_risk, '') == 'veryhigh'
              "
              class="pr-1 lg:pr-0 2xl:pr-1"
            >
              <ExclamationCircleIcon class="h-4 w-4"
            /></span>
            <div class="uppercase">
              {{ nft?.ownership_risk }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </BaseCard>
</template>

<script setup>
import { slugify } from '../composeables/filters';
import IconAvalanche from '../icons/IconAvalanche.vue';
import { PhotoIcon } from '@heroicons/vue/20/solid';
import { ExclamationCircleIcon } from '@heroicons/vue/24/outline';
import { Link } from '@inertiajs/inertia-vue3';
import { decimals, formatNumber, isValidUrl } from '../composeables/filters.js';
import { computed, onMounted, ref } from 'vue';
import BaseCard from '../Shared/BaseCard.vue';
const emit = defineEmits(['nft-clicked']);
const props = defineProps({
  nft: Object,
  boxBg: {
    type: String,
    default: '',
  },
  hideName: {
    type: Boolean,
    default: false,
  },
  boxWidth: {
    type: String,
    default: 'w-full',
  },
});
const available = ref(true);
onMounted(() => {});
const imageUrl = computed(() => {
  return props.nft?.token_image || props.nft?.collection_image_url;
});
const rarityRank = computed(() => {
  return props.nft?.rarity_status;
});
const avaxPrice = computed(() => {
  if (props.nft?.listing) {
    let listing = JSON.parse(props.nft?.listing);
    if (listing?.price?.currency?.name == 'Avalanche') {
      return listing.price.currency.decimals;
    }
    return '';
  } else {
    return ' ';
  }
});
const usdPrice = computed(() => {
  if (props.nft?.listing) {
    let listing = JSON.parse(props.nft?.listing);
    if (listing?.price?.amount?.usd) {
      return listing.price.amount?.usd;
    }
    return '';
  } else {
    return ' ';
  }
});
function nftClicked() {
  emit('nft-clicked', {
    collection: props.nft?.collection,
    tokenId: props.nft?.token_id,
    name: props.nft?.collection_name,
  });
}
function setupPrice(price) {
  price = parseFloat(price);
  if (price) {
    price = price * Math.pow(10, -18);
  }
  return price;
}
</script>
