<template>
  <div class="modal box-shadow relative flex flex-col items-center text-xs font-normal !w-full"
        id="privacyPolicyModal">
        <div class="prose prose-sm text-whiteSmokeAlpha-56 max-w-full">
            <div class="flex justify-end w-full" >
                <XMarkIcon class="h-5 w-5 cursor-pointer hover:scale-125" @click="closeModal" />
            </div>
            <h2 class="text-gray-300 !mt-0 text-center">
                <div>FROSTY METRICS Privacy Policy</div>
                <div class="text-gray-400 text-[10px] left-0">Updated: April 11, 2024</div>
            </h2>
            <div class="w-full overflow-auto md:h-80 h-96">
                <h4 class="text-gray-300">INTRODUCTION</h4>
                <p>
                The Tie Inc. and its affiliates (together "Company," "we," "us," or "our")
                as the responsible party for recording data on the Frosty Metrics website
                (the “controllers”) respect your privacy and are committed to protecting
                it through our compliance with this Policy. This Policy describes the
                types of information we may collect from you or that you may provide when
                you visit the website, <a href="https://www.frostymetrics.com/" class="text-blue-400"
                >www.frostymetrics.com</a> (the “Website” or
                “Site”), and our practices for collecting, using, maintaining, protecting,
                and disclosing that information. This Policy applies to information we
                collect on the Website. It does not apply to information collected by:
                </p>
                <ul>
                <li>
                    Us offline or through any other means, including on any other website
                    operated by the Company or any third party (including our affiliates and
                    subsidiaries); or Any third party (including our affiliates and
                    subsidiaries), including through any application or content, if any,
                    that may link to or be accessible from or through our Site at any time.
                </li>
                </ul>
                <p>
                Please read this Policy carefully to understand our policies and practices
                regarding your information and how we will treat it. If you do not agree
                with our policies and practices, your choice is to not use our Site. By
                accessing or using this Site, you agree to this Privacy Policy. This
                Policy may change from time to time (see Changes to Our Privacy Policy).
                Your continued use of this Site after we make changes is deemed to be
                acceptance of those changes, so please check the Policy periodically for
                updates.
                </p>
                <h5 class="text-gray-300">
                At all times, your use of the Site is subject to the Terms of Use, which
                incorporates this Privacy Policy.
                </h5>
                <h4 class="text-gray-300" id="section1">
                INFORMATION WE COLLECT ABOUT YOU AND HOW WE COLLECT IT
                </h4>
                <p>
                We collect several types of information the use of our Site, including
                information:
                </p>
                <ul>
                <li>
                    Signature, online identifier, internet protocol address or similar
                    identifiers;
                </li>
                <li>
                    The on-chain addresses that you chose to enter on the Site. Note that
                    the information connected to the addresses is public. We do not verify
                    the holder to the address and do not collect data from which you can be
                    personally identified (i.e., we do not collect name, email address or
                    other such identifiers); and/or About your internet connection, the
                    equipment you use to access our Site, and usage details
                </li>
                </ul>
                <h4 class="text-gray-300">We may collect this information:</h4>
                <ul>
                <li>
                    Directly from you when you provide it to us by entering your wallet
                    address; and/or Automatically as you navigate through the Site.
                    Information collected automatically may include usage details, IP
                    addresses, and information collected through cookies and/or other
                    tracking technologies
                </li>
                </ul>
                <h4 class="text-gray-300">
                Information We Collect Through Automatic Data Collection Technologies
                </h4>
                <div>
                As you navigate through and interact with our Site, we may use automatic
                data collection technologies to collect certain information about your
                equipment, browsing actions, and patterns, including:
                </div>
                <ul>
                <li>
                    Details of your visits to our Site, including geolocation, traffic
                    data, location data, logs, and other communication data and the
                    resources that you access and use on the Site; and Information about
                    your computer and internet connection, including your IP address,
                    operating system, and browser type.
                </li>
                </ul>
                <p>
                The information we collect automatically is only statistical data and does
                not include personal information, but we may maintain it or associate it
                with personal information we collect in other ways or receive from third
                parties. It helps us to improve our Site. The technologies we use for this
                automatic data collection may include cookies (or browser cookies), flash
                cookies or web beacons.
                </p>
                <section>
                <h4 class="text-gray-300">HOW WE USE YOUR INFORMATION</h4>
                <p class="pb-4">
                    We use information that we collect for carefully-considered purposes as
                    follows:
                </p>
                <ul>
                    <li>  To present our Site and its contents to you;</li>
                    <li>
                    To fulfill any other purpose for which you provide it including, but
                    not limited to, providing you with information, such as a score,
                    relative your usage of the Avalanche ecosystem and/or additional usage
                    recommendations if you chose to provide your on-chain address.
                    </li>
                    <li>
                    To gain insight into the use of the Avalanche ecosystem to measure and
                    share impact for informational and training purposes.
                    </li>
                    <li>
                    To allow you to participate in interactive features on our Site
                    </li>
                    <li>  To analyze use of our Site.</li>
                    <li>
                    In any other way we may describe when you provide the information; and
                    </li>
                    <li>  For any other purpose with your consent.</li>
                </ul>
                </section>
                <section>
                <h4 class="text-gray-300">DISCLOSURE OF YOUR INFORMATION</h4>
                <p>
                    We may disclose aggregated information about our users, and information
                    that does not identify any individual, without restriction.
                </p>
                <p>We do not
                    collect your personal information and do not anticipate that any
                    personal information will be provided to us. If personal information is
                    provided to us, we may disclose personal information that we collect or
                    you provide as described in this Privacy Policy:</p>
                <ul>
                    <li>  To our subsidiaries and affiliates.</li>
                    <li>
                    To contractors, service providers, and other third parties we use to
                    support our business and who are bound by contractual obligations to
                    keep personal information confidential and use it only for the
                    purposes for which we disclose it to them.
                    </li>
                    <li>  To fulfill the purpose for which you provide it.</li>
                    <li>
                    For any other purpose disclosed by us when you provide the
                    information.
                    </li>
                    <li>  With your consent.</li>
                </ul>
                <p>We may also disclose personal information, if any:</p>
                <ul>
                    <li>
                    To comply with any court order, law, or legal process, including to
                    respond to any government or regulatory request;
                    </li>
                    <li>
                    To enforce or apply our Terms of Use and other agreements, including
                    for billing and collection purposes;
                    </li>
                    <li>
                    If we believe disclosure is necessary or appropriate to protect the
                    rights, property, or safety of the Company, our customers, or others.
                    </li>
                </ul>
                <p>
                    If personal information is provided to us for any reason, you may send
                    us an email at finance@thetie.io or legal@thetie.io to request access
                    to, correct, or delete the personal information, if any, that you have
                    provided to us. We may not accommodate a request to change information
                    if we believe the change would violate any law or legal requirement or
                    cause the information to be incorrect.
                </p>
                </section>
                <section>
                <h4 class="text-gray-300">YOUR STATE PRIVACY RIGHTS</h4>
                <p>
                    If you are a California, Virginia, Colorado, Connecticut, Utah, Iowa,
                    Indiana Tennessee, Texas, Florida, Montana, Oregon or Delaware resident,
                    your State has passed legislation regarding your privacy rights. Of
                    those thirteen States, California, Colorado, Connecticut, and Virginia
                    laws are currently effective.
                </p>
                <p>
                    To learn about your California privacy rights, see California Privacy
                    Rights.
                </p>
                <p>
                    To learn more about your Colorado, Virginia, or Connecticut privacy
                    rights and how to exercise them, please make a written request to
                    legal@thetie.io
                </p>
                <p>
                    To make a request regarding additional information based upon any other
                    jurisdiction, please send an email to legal@thetie.io. While we will
                    make reasonable efforts to accommodate any request, we also reserve the
                    right to place limitations on any request, subject to applicable laws.
                </p>
                </section>
                <section>
                <h4 class="text-gray-300">CALIFORNIA PRIVACY RIGHTS</h4>
                <p>
                    The California Consumer Privacy Act (CCPA) provides California residents
                    with additional rights as it relates to the protection of personal data.
                </p>
                <p>
                    “Personal information” refers to information that identifies, relates
                    to, describes, is reasonably capable of being associated with, or could
                    be reasonably linked, directly or indirectly, with you.
                </p>
                <p>
                    If you think the Company has misappropriated your personal information,
                    California consumers have a right to:
                </p>

                <ul>
                    <li>
                    Know about the personal information the Company collects about you;
                    </li>
                    <li>  Correct inaccurate personal information;</li>
                    <li>  Limit the use and disclosure of sensitive personal information</li>
                    <li>
                    Delete personal information collected by the Company; and receive
                    non-discriminatory treatment against you for exercising any of these
                    rights.
                    </li>
                </ul>
                <p>
                    You acknowledge that by requesting certain types of personal data be
                    deleted or removed from the Company’s permissible usage, it may
                    consequently affect your user experience of the Site.
                </p>
                <p>
                    Some examples of “personal information” as defined by the CCPA include:
                </p>
                <ul>
                    <li>
                    Name, alias, postal address, IP Address, email address, account name,
                    Social Security number, driver’s license number, passport number, or
                    other similar identifiers;
                    </li>
                    <li>
                    <Caption></Caption>ommercial information including records of personal property,
                    products or services purchased, obtained or considered, or other
                    purchasing or consuming histories or tendencies;
                    </li>
                    <li>
                    Internet or other electronic network activity information including,
                    but not limited to, browsing history, search history and information
                    regarding a consumer’s interaction with a website, application, or
                    advertisement and geolocation data.
                    </li>
                </ul>
                <p>
                    Personal information, as defined by the CCPA, does not include publicly
                    available information that is from federal, state or local government
                    records or other information that is publicly available.
                </p>
                <p>
                    The Company collects the following types of information which may
                    constitute “sensitive personal information” as defined under the CCPA:
                    log-in and password, email address, signature, online identifier (i.e.,
                    IP address), and certain information about your internet connection
                    (i.e., device type). The Company only uses such information to provide
                    our services and products or as otherwise permitted by the CCPA. The
                    Company does not sell or share your personal information to third
                    parties and/or other affiliates.
                </p>
                <p>
                    For more information about how we collect, use, and retain your
                    information as well as updates, see
                    <b>INFORMATION WE COLLECT ABOUT YOU AND HOW WE COLLECT IT</b> <a @click="scrollToReference('section1')" class="cursor-pointer">(above).</a>
                </p>
                <p>
                    California customers can exercise their rights directly or through an
                    authorized agent by contacting the Company’s Legal Department. Please
                    include “California Privacy Rights” in the Subject line of your email,
                    if sending an inquiry electronically.
                </p>
                <p>
                    For more information regarding the Company’s use of your data, send
                    inquiries to the Company’s Legal Department
                </p>
                <p>Email: legal@thetie.io</p>
                <p>Mailing Address: 41 W 25th Street, 5th Floor, New York, NY 10010</p>
                </section>
                <section>
                <h4 class="text-gray-300">EUROPEAN PRIVACY RIGHTS (GDPR)</h4>
                <p>
                    The European Union’s General Data Protection Regulation (GDPR) provides
                    customers in the European Economic Area (EEA) with additional rights as
                    it relates to protection of personal data.
                </p>
                <p>
                    References to “personal information” in this Policy are equivalent to
                    “personal data” as defined under GDPR. “Personal information” or
                    “personal data” refers to information that identifies, relates to,
                    describes, is reasonably capable of being associated with, or could be
                    reasonably linked, directly or indirectly, with you.
                </p>
                <p>
                    Under the GDPR, a company may be a “data controller,” “data processor”
                    or both.
                </p>
                </section>
                <section>
                <h4 class="text-gray-300">DATA CONTROLLER</h4>
                <p>
                    Under Article 4 of the GDPR, a company is a “data controller” when it
                    determines the purposes and means of processing personal data. In the
                    Company’s role as “data controller,” we ensure that European customers
                    have the right to access the data gathered about them and subsequently
                    request a correction, minimization or deletion of the data collected
                    depending on the individual circumstances, with the acknowledgement that
                    in doing so, your experience of the Site may be impacted. When acting as
                    data controller, the Company commits to:
                    <ul>
                        <li>  Take into account the nature, scope, context, and purposes of processing; and implement appropriate measures to ensure the ability to demonstrate processing is in accordance with the GDPR.</li>
                    </ul>
                </p>
                <h4 class="text-gray-300">DATA PROCESSOR</h4>
                <p>Under Article 4 of the GDPR, a company is a “data processor” when it processes personal data on behalf of a “data controller.”  In the Company’s role as “data processor,” we process your data safely and legally; and while the majority of the Company’s business operates as a “data controller,” circumstances may arise where we process data. </p>
                <p>Under Article 2 of the GDPR, the Company retains the right to process personal data by competent authorities for the purposes of the prevention, investigation, detection or prosecution of criminal offenses or the execution of criminal penalties.</p>
                <p>In accordance with this Privacy Policy and the GDPR, personal data shall be:</p>
                <ul><li>	Processed lawfully, fairly and in a transparent manner in relation to you;
                    collected for specified, explicit and legitimate purposes;
                    adequate, relevant and limited to what is necessary;
                    accurate and, where necessary, kept up to date;
                    kept in a form that permits identification of you for no longer than is necessary; and
                    processed in a manner that ensures appropriate security of personal data.
                </li></ul>
                <p>In the event of a data breach, the Company will abide by GDPR requirements of notification and remediation.  The Company will report data breaches within a seventy-two-(72)-hour time frame, or if delayed will give a reason and response for such a delay.  If a data breach occurs and puts certain individuals in high risk, the Company will inform those individuals involved without undue delay.</p>
                <p>The Company only retains data so long as is necessary and for the purposes for which it was collected.  The Company will retain your data for the necessary retention period and abide by applicable retention period criteria.  The Company will not keep your personal information for longer than is necessary.  Reasons for retaining your data may include, but are not limited to, increased user accessibility of the Site, to comply with legal inquiries, and to help law enforcement with ongoing investigations.</p>
                <p>You shall have the right to:</p>
                <ul>
                    <li>Ask whether we have any personal data about you and request a copy of such personal data.</li>
                    <li>Request that we update or correct inaccuracies in your personal data</li>
                    <li>Request that we restrict the processing of your personal data if such processing is inappropriate</li>
                    <li>And object to our processing of your personal data if processing is inappropriate.</li>
                </ul>
                <p>You acknowledge that by requesting certain types of personal data be deleted from the Company’s permissible usage, it may consequently affect your user experience of the Site. </p>
                <p>For more information about how we collect, use, and retain your information as well as updates, see <b>INFORMATION WE COLLECT ABOUT YOU AND HOW WE COLLECT IT</b> <a @click="scrollToReference('section1')" class="cursor-pointer">(above).</a></p>
                <p>European customers can exercise their rights directly or through an authorized agent by contacting the Company’s Legal Department.  Please include “European Privacy Rights” in the Subject line of your email.</p>
                <p>For more information regarding the Company’s use of your data, send inquiries to the Company’s Legal Department:</p>
                <p>Email: legal@thetie.io</p>
                <p>Mailing Address: 41 W 25th Street, 5th Floor, New York, NY 10010</p>
                </section>
                <section>
                <h4 class="text-gray-300">ADDITIONAL PROVISIONS:</h4>
                <h4 class="text-gray-300">DATA SECURITY</h4>
                <p>Any information that you provide to us is kept secure; however, the safety and security of your information also depends on you.</p>
                <h4 class="text-gray-300">CHANGES TO OUR PRIVACY POLICY</h4>
                <p>It is our policy to post any changes we make to our Privacy Policy on this page.  If we make material changes to how we treat our users' personal information, we will notify you through a notice on the Site home page.  The date the Privacy Policy was last revised is identified at the top of the page.  You are responsible for ensuring we have an up-to-date active and deliverable email address for you, and for periodically visiting our Site and this Privacy Policy to check for any changes.</p>
                <h4 class="text-gray-300">CONTACT INFORMATION</h4>
                <p>To ask questions or comment about this Privacy Policy and our privacy practices, contact us at:  The Tie Inc., 41 West 25th Street, 5th Floor, New York, NY 10010 or by email to legal@thetie.io.</p>
                <p>To register a complaint or concern, please email legal@thetie.io.  Note that we may reject requests which risk the privacy of others or would require a disproportionate technical effort or which would otherwise be impractical.</p>
                <h4 class="text-gray-300">TRANSFER OF YOUR PERSONAL INFORMATION TO COUNTRIES OTHER THAN THE UNITED STATES</h4>
                <p>Our Site is used by customers throughout the world.  Some of these countries have different levels of privacy protection than those in the U.S.  By using our Site and our products and services, you acknowledge that you agree to any transfer and processing of personal information, including to and in the United States.  We implement appropriate safeguards to protect any information that is transferred.</p>
                <h4 class="text-gray-300">LEGAL AND DISCLAIMER</h4>
                <h4 class="text-gray-300">LIMITATION OF LIABILITY</h4>
                <p>Where any information on our Site is provided free of charge, you acknowledge that it would be unreasonable to hold us liable with respect to the Site and the information contained thereon.</p>
                <p>REGARDLESS OF THE TYPE OF CLAIM OR THE NATURE OF THE CAUSE OF ACTION, TO THE EXTENT ALLOWED BY LAW, YOU UNDERSTAND AND EXPRESSLY AGREE THAT IN NO EVENT WILL THE COMPANY OR ITS AFFILIATES, OFFICERS, EMPLOYEES, AGENTS OR LICENSORS BE LIABLE FOR:  (I) ANY INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, EXEMPLARY, OR PUNITIVE DAMAGES, INCLUDING, BUT NOT LIMITED TO, LOSS OF REVENUES, DATA AND PROFITS, OR OTHER INTANGIBLE LOSSES, EVEN IF THE COMPANY HAS BEEN ADVISED AS TO THE POSSIBILITY OF SUCH DAMAGES; OR (II) TOTAL CUMULATIVE LIABILITY ARISING UNDER OR RELATED TO OUR SITE, WHETHER IN CONTRACT, TORT, OR OTHERWISE.  SOME JURISDICTIONS DO NOT ALLOW THE LIMITATION OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES.  IN THOSE JURISDICTIONS, THE COMPANY'S LIABILITY SHALL BE LIMITED TO THE GREATEST EXTENT PERMITTED BY LAW.  YOU UNDERSTAND AND AGREE THAT YOUR USE OF OUR SITE IS PREDICATED UPON YOUR WAIVER OF ANY RIGHT TO SUE THE COMPANY, ITS OWNERS OR ITS AFFILIATES DIRECTLY, OR TO PARTICIPATE IN A CLASS ACTION SUIT FOR ANY LOSSES OR DAMAGES RESULTING FROM YOUR USE OF OUR SITE.</p>
                <p>IN ALL CASES, NEITHER THE COMPANY NOR ITS AFFILIATES ASSUME ANY RESPONSIBILITY OR LIABILITY FOR ANY ERRORS OR OMISSIONS ON OUR SITE OR WITH RESPECT TO SITE MATERIALS, ANY FAILURES, DELAYS, OR INTERRUPTIONS IN OUR SITE'S ACCESSIBILITY, ANY LOSSES OR DAMAGES ARISING FROM THE USE OF OUR SITE OR SITE MATERIALS, ANY CONDUCT BY OTHER USERS OF OUR SITE, OR UNAUTHORIZED ACCESS TO OR USE OF OUR SITE OR SITE MATERIALS.</p>
                <p>Our goal is to help you make more informed decisions; however, you are fully responsible for any decisions you make.  We are not liable for any loss or damage caused by reliance on information contained on our Site or in any of our communications.</p>
                <p>This Site cannot assist you with respect to any regulatory complaint.  If you have a specific complaint about the trading of a cryptocurrency, or about an exchange, please register your complaint with an appropriate regulatory entity.  For example, for more information, see www.sec.gov (U.S. Securities and exchange Commission), www.finra.org (Financial Industry Regulatory Authority), or www.nasaa.org (North American Securities Administrators Association).</p>
                <p>ARBITRATION NOTICE:  YOU AGREE THAT DISPUTES BETWEEN YOU AND US WILL BE RESOLVED BY BINDING, INDIVIDUAL ARBITRATION AND YOU WAIVE YOUR RIGHT TO PARTICIPATE IN A CLASS ACTION LAWSUIT OR CLASS-WIDE ARBITRATION.</p>
                </section>
            </div>
        </div>
  </div>
</template>
<script setup>
import { XMarkIcon } from '@heroicons/vue/24/outline';

const props = defineProps({
  showModal: Boolean,
});
const emit = defineEmits(['close-modal']);

function closeModal() {
    console.log("closeModal");
  emit('close-modal', { showModal: false });
};

function scrollToReference(referenceId) {
  const element = document.getElementById(referenceId);
  if (element) {
    element.scrollIntoView({ behavior: 'smooth' });
  }
}
</script>

