<template>
  <div class="modal w-full rounded-lg shadow-md z-50">
    <div class="flex w-full justify-end">
      <XMarkIcon class="h-5 w-5 cursor-pointer hover:scale-125" @click="closeModal" />
    </div>
    <div class="mb-6 flex items-center justify-center space-x-1">
      <h1 class="text-center text-2xl text-white">Add Your Project Form</h1>
    </div>
    <form @submit.prevent="handleSubmit">
        <div class="mb-4">
            <p v-if="confirmationMessage" class="text-md mb-2 flex space-x-1">
                <CheckCircleIcon class="h-6 w-6 text-green-500" />
                <div>{{ confirmationMessage }}</div>
            </p>
            <label
                for="name"
                class="mb-2 block px-0.5 text-sm font-medium text-white"
                >Project Name:</label
            >
            <input
                type="text"
                id="name"
                v-model="formData.name"
                class="w-full rounded-md bg-neutral-800 px-3 py-2 text-white focus:outline-none"
            />
        </div>
        <div class="mb-4">
            <label
            for="tab"
            class="mb-2 block px-0.5 text-sm font-medium text-white"
            >Select Project Tab:</label
            >
            <div class="space-x-5 flex text-sm">
                <div class="space-x-1 flex items-center ">
                    <input type="radio" id="DeFi" value="DeFi" v-model="formData.tab" />
                    <label for="DeFi">DeFi</label>
                </div>
                <div class="space-x-1 flex items-center ">
                    <input type="radio" id="Gaming" value="Gaming" v-model="formData.tab" />
                    <label for="Gaming">Gaming</label>
                </div>
                <div class="space-x-1 flex items-center ">
                    <input type="radio" id="Subnets" value="Subnets" v-model="formData.tab" />
                    <label for="Subnets">L1s</label>
                </div>
                <div class="space-x-1 flex items-center ">
                    <input type="radio" id="Other" value="Other" v-model="formData.tab" />
                    <label for="Other">Other</label>
                </div>
            </div>
        </div>
        <div class="mb-4">
            <label
            for="description"
            class="mb-2 block px-0.5 text-sm font-medium text-white"
            >Project Description:</label
            >
            <input
            type="text"
            id="description"
            v-model="formData.description"
            class="w-full rounded-md bg-neutral-800 px-3 py-2 text-white focus:outline-none"
            />
        </div>
        <div class="mb-4">
            <div class="flex items-center text-base font-normal mb-2">
                Relevant C-Chain contract addresses
                <Tooltip text="Please share all relevant C-Chain contract addresses - with descriptions/names - that we should track for your project (You can link to your contract documentation or provide a google sheet if desired)" class="ml-1">
                    <InformationCircleIcon class="h-4 w-4" />
                </Tooltip>
            </div>
            <textarea
            id="chainContractAddresses"
            v-model="formData.chainContractAddresses"
            class="w-full rounded-md bg-neutral-800 px-3 py-2 text-white focus:outline-none"
            ></textarea>
        </div>
        <div class="mb-4">
            <label
            for="extraDetails"
            class="mb-2 block px-0.5 text-sm font-medium text-white"
            >Is there anything else you'd like us to know?</label
            >
            <textarea
            id="extraDetails"
            v-model="formData.extraDetails"
            class="w-full rounded-md bg-neutral-800 px-3 py-2 text-white focus:outline-none"
            ></textarea>
        </div>
        <div class="flex justify-end">
            <button
            type="submit"
            :disabled="!isFormValid"
            class="rounded-md bg-indigo-600 px-4 py-2 text-white hover:bg-indigo-700 disabled:cursor-not-allowed disabled:bg-gray-500"
            >
            Request
            </button>
        </div>
    </form>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import { XMarkIcon, CheckCircleIcon, InformationCircleIcon } from '@heroicons/vue/24/outline';
import Tooltip from './Tooltip.vue';

const emit = defineEmits(['close-modal']);

const formData = ref({});
const submitted = ref(false);
const confirmationMessage = ref('');

const isFormValid = computed(() => {
  return (
    formData.value.name?.length > 0 &&
    formData.value.tab?.length > 0 &&
    formData.value?.chainContractAddresses?.length > 0
  );
});

const handleSubmit = async () => {
    console.log("formData", formData.value)
  submitted.value = true;

  if (isFormValid.value) {
    const webhookUrl =
    'https://discord.com/api/webhooks/1275104079047168111/fkiX_lvfTpdKOwiIsNMr5M5iWIUlzOxFoI18eeI5YVszDFBSSz9Fu4ihVRUtY9dxDLmf'
    try {
      const res = await fetch(webhookUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          content: `Protocol Name: ${formData.value.name}\nTab Name: ${formData.value.tab}\nDescription: ${formData.value.description}\nC-Chain Contract Addresses: ${formData.value.chainContractAddresses}\nExtra Details: ${formData.value.extraDetails}\n`,
        }),
      });

      if (res.ok) {
        confirmationMessage.value =
          'Thank you! Your request has been successfully submitted.';
      } else {
        confirmationMessage.value = 'Something went wrong! Try again later.';
      }
    } catch (error) {
      confirmationMessage.value = 'Something went wrong! Try again later.';
    }

    setTimeout(() => {
      closeModal();
    }, 3000);
  }
};

const closeModal = () => {
  formData.value = { name: '', tab: '', description: "", chainContractAddresses: "", extraDetails: "" };
    confirmationMessage.value = '';
    submitted.value = false;
    emit('close-modal', { showModal: false });
};
</script>
