<template>
  <Teleport to="body" v-if="navbarModal">
    <transition name="fade" appear>
      <div class="h-full w-full" v-if="mobileMenuView" @click="close()"></div>
    </transition>
    <transition name="slide" appear>
      <div
        class="menuModal box-shadow relative flex flex-col items-center border border-grid bg-[#9D9D9D3D] text-base font-normal backdrop-blur-2xl"
        id="menuModal"
      >
        <!-- CLOSE AND STATUS -->
        <div
          class="flex w-full items-center justify-between px-8 pt-16 text-xs"
        >
          <!-- X BUTTON -->
          <div class="cursor-pointer rounded border p-1" @click="close()">
            <XMarkIcon class="h-5 w-5" />
          </div>

          <!-- CHILL FACTOR STATUS -->
          <div class="">
            <button
              v-if="myAddress?.length == 0"
              @click="openModal()"
              class="h-10 rounded bg-gradient-to-br from-[#31E0FF] to-[#45A8FF] py-2 px-6 text-black"
            >
              Connect Wallet
            </button>
            <button
              v-else
              class="h-10 rounded-3xl border border-[#FFFFFF3D] px-4 font-bold"
              :class="chillFactorColor || 'bg-gray-800'"
            >
              <span :class="chillFactorTextColor">{{
                rankTierValue?.tier
              }}</span>
              <span
                v-if="tiers.includes(rankTierValue?.tier?.toLowerCase())"
                class="ml-2 font-light text-[#f3f2f6e0]"
                >#{{ simpleNumberFormatter(rankNum) }}</span
              >
            </button>
          </div>
        </div>

        <!-- GLOBAL SEARCH-->
        <GlobalSearch class="mb-2 mt-6 h-10 px-4 sm:hidden sm:h-full" />
        <!-- LINKS-->
        <div class="w-full flex-col items-center justify-between py-2">
          <div
            class="w-full flex-grow text-sm text-white"
            v-for="link in links"
            :key="link.url"
            @click="close()"
          >
            <Link :href="link.url">
              <div
                class="flex w-full cursor-pointer items-center justify-center py-4 text-sm"
                :class="`${
                  link.activeTab.includes(findCurrentUrl($page.url))
                    ? 'bg-[#FFFFFF29] font-semibold text-[#FFFF]'
                    : ''
                }`"
              >
                {{ link.name }}
              </div>
            </Link>
          </div>
        </div>

        <div class="w-full px-2">
          <div class="border-b border-slate-600 px-4 opacity-20"></div>
          <div
            class="flex space-x-2 border-t border-gray-500 px-2 py-2 text-sm"
          >
            <AnimationToggle class="w-full" />
          </div>
        </div>
        <!--  -->
        <div class="w-full space-y-8 py-4">
          <div
            @click="openModal('privacy_policy')"
            class="flex cursor-pointer justify-center"
          >
            Privacy Policy
          </div>
          <div
            @click="openModal('terms_of_use')"
            class="flex cursor-pointer justify-center"
          >
            Terms of Use
          </div>
          <div
            @click="openModal('add_your_project')"
            class="flex cursor-pointer justify-center rounded-md bg-indigo-600 px-4 py-2 text-white hover:bg-indigo-700"
          >
            Add Your Project
          </div>
        </div>
      </div>
    </transition>
  </Teleport>
  <Modal
    :modalType="modalType"
    :show-modal="privacyPolicyModal"
    @closeModal="closeModal()"
  />
</template>
<script setup>
import { ref, defineProps, computed, inject, watchEffect } from 'vue';
import { Link } from '@inertiajs/inertia-vue3';
import { simpleNumberFormatter, setTier } from '../composeables/filters';
import { XMarkIcon } from '@heroicons/vue/24/outline';
import GlobalSearch from '../Widgets/GlobalSearch.vue';
import AnimationToggle from './AnimationToggle.vue';
import Modal from './Modal.vue';
const emit = defineEmits(['close-modal']);

const props = defineProps({
  mobileMenuView: {
    type: Boolean,
  },
  myAddress: {
    type: Object,
  },
  chillFactorTextColor: {
    type: String,
  },
  rankTierValue: {
    type: Object,
  },
});

const chillFactorColor = computed(() => {
  showRankTier(props.rankTierValue);
  return `${props.rankTierValue?.tier?.toLowerCase()}-button-bg`;
});

const tiers = ['warm', 'lukewarm', 'chilly', 'frozen', 'subzero'];
function showRankTier(rankTierValue) {
  if (rankTierValue?.score && rankTierValue.rank) {
    let tier = setTier(rankTierValue?.score);
    tier = tier ? tier : rankTierValue?.tier;
  }
}

function close() {
  emit('close-modal', { showModal: false });
}

const links = ref([
  {
    name: 'Dashboard',
    url: '/dashboard',
    activeTab: ['dashboard'],
  },
  {
    name: 'Chill Factor',
    url: '/chill_factor',
    activeTab: ['chill_factor'],
  },
  {
    name: 'DeFi',
    url: '/defi',
    activeTab: ['defi'],
  },
  {
    name: 'Gaming',
    url: '/gaming',
    activeTab: ['gaming', 'projects', 'tokens'],
  },
  {
    name: 'NFT',
    url: '/nfts',
    activeTab: ['nfts'],
  },
  {
    name: 'Avalanche L1s',
    url: '/l1',
    activeTab: ['l1', 'subnets'],
  },
]);
function findCurrentUrl(url) {
  let currentUrl = url.split('/').filter((x) => x)[0];
  return currentUrl === undefined ? 'dashboard' : currentUrl;
}

// set navbar modal visibility
const navbarModal = ref(true);
// Modals
const privacyPolicyModal = ref(false);
const modalType = ref('');
function openModal(type) {
  navbarModal.value = false;
  modalType.value = type;
  privacyPolicyModal.value = true;
  document.body.classList.add('overflow-y-hidden');
}
function closeModal() {
  navbarModal.value = true;
  privacyPolicyModal.value = false;
  document.body.classList.remove('overflow-y-hidden');
}

const rankNum = ref(localStorage.getItem('rankNumber'));
// In the Navbar
const sharedState = inject('sharedState');
watchEffect(() => {
  console.log();
  rankNum.value =
    sharedState.value == true
      ? props.rankTierValue?.filtered_rank
      : props.rankTierValue?.rank;
});
</script>
<style scoped>
.menuModal {
  position: fixed;
  left: 20%;
  top: 0%;
  z-index: 999;
  width: 80%;
  height: 100%;
  max-width: 90%;
  max-height: 100%;
  box-shadow: 0px 0px 16px 0px rgba(255, 255, 255, 0.04) inset;
  box-shadow: 4px 14px 86.5px 0px rgba(162, 62, 0, 0.32);
  background-color: 'rgba(157, 157, 157)!important';
}
</style>
