<template>
  <Teleport to="body">
    <transition name="fade" appear>
      <div class="h-full w-full" v-if="showModal" @click="close()">
        <div class="modal-overlay">
          <span v-if="tier?.tier?.toLowerCase() == 'warm'">
            <div v-for="index in 5" :key="index">
              <div class="grid h-[20vh] w-full grid-cols-3 gap-0">
                <RainAnimation />
                <RainAnimation />
                <RainAnimation />
              </div>
            </div>
          </span>
          <span v-else-if="tier?.tier?.toLowerCase() != 'warm'">
            <SnowAnimation />
          </span>
        </div>
      </div>
    </transition>
    <transition name="slide" appear>
      <div id="modal" v-if="showModal">
        <div class="flex flex-col space-y-3">
          <div class="modal flex w-full justify-between" v-if="collectionName">
            <Link :href="`/nfts/${props?.collection}`">
              <span class="font-semibold text-lg"
                >{{ collectionName }} #{{ tokenId }}</span
              >
            </Link>
            <span class="cursor-pointer items-center" @click="close()">
              <XMarkIcon class="h-5 w-5 cursor-pointer" />
            </span>
          </div>
          <div
            v-if="!loading && modalType?.length == 0"
            class="h-[25rem] w-full space-y-3 overflow-auto sm:flex sm:h-auto sm:space-x-3 sm:space-y-0 sm:overflow-hidden"
          >
            <div class="flex w-full sm:w-1/2">
              <img
                v-if="tks?.token_image"
                :src="tks?.token_image"
                class="h-[21.5rem] w-full rounded-lg object-cover"
              />
              <span
                v-else
                class="flex h-[21.5rem] w-full flex-col items-center rounded-lg bg-secondary text-gray-400"
              >
                <div class="my-auto flex flex-col items-center">
                  <PhotoIcon class="h-20 w-12" />
                  <div>Image Not Found</div>
                </div>
              </span>
            </div>
            <div class="flex w-full sm:w-1/2">
              <div class="flex w-full flex-col space-y-4">
                <NoDataFound
                  v-if="_.isEmpty(tks)"
                  class="flex h-[7.3rem] rounded-lg bg-secondary"
                />
                <div
                  class="flex h-[7.3rem] flex-col space-y-3 rounded-lg bg-secondary p-4"
                  v-else
                >
                  <div class="flex w-full justify-between text-sm">
                    <span class="text-gray-400">Avg Sale</span>
                    <span class="flex space-x-1">
                      <IconAvalanche class="w-4 text-gray-500" />
                      <span v-if="tks?.avg_price">{{
                        formatNumber(setupPrice(tks.avg_price))
                      }}</span>
                      <span class="text-gray-500" v-else>n/a</span>
                    </span>
                  </div>
                  <div class="flex w-full justify-between text-sm">
                    <span class="text-gray-400">All Time High</span>
                    <span class="flex space-x-1">
                      <IconAvalanche class="w-4 text-gray-500" />
                      <span v-if="tks?.max_price">{{
                        formatNumber(setupPrice(tks.max_price))
                      }}</span>
                      <span class="text-gray-500" v-else>n/a</span>
                    </span>
                  </div>
                  <div class="flex w-full justify-between text-sm">
                    <span class="text-gray-400">All Time Low</span>
                    <span class="flex space-x-1">
                      <IconAvalanche class="w-4 text-gray-500" />
                      <span v-if="tks?.min_price">{{
                        formatNumber(setupPrice(tks.min_price))
                      }}</span>
                      <span class="text-gray-500" v-else>n/a</span>
                    </span>
                  </div>
                </div>
                <div
                  class="flex h-[13.3rem] flex-col space-y-3 overflow-auto rounded-lg bg-secondary p-4"
                >
                  <div
                    class="flex w-full justify-between text-sm"
                    v-for="(trait, i) in traits_held_by_token"
                    :key="i"
                  >
                    <span class="capitalize text-gray-400">{{ trait }}</span>
                    <span :title="tks.trait_values[i]" class="capitalize">{{
                      truncate(tks.trait_values[i], 20)
                    }}</span>
                  </div>
                  <NoDataFound
                    v-if="traits_held_by_token.length == 0"
                    class="my-auto"
                  />
                </div>
              </div>
            </div>
          </div>
          <div v-else-if="modalType == 'privacy_policy'">
            <PrivacyPolicyModal @closeModal="close()" />
          </div>
          <div v-else-if="modalType == 'terms_of_use'">
            <TermsOfUseModal @closeModal="close()" />
          </div>
          <div v-else-if="modalType == 'add_your_project'">
            <AddYourProject @closeModal="close()" />
          </div>
          <div v-else-if="modalType == 'nft'">
            <NftModal />
          </div>
          <div class="flex w-full justify-end">
            <Dropdown
              btnClasses="bg-secondary"
              btnName="Trade"
              :items="marketplaceLists"
              :position="position"
              @selected="(x) => selected(x)"
              v-if="!_.isEmpty(tks)"
            />
          </div>
        </div>
      </div>
    </transition>
  </Teleport>
</template>
<script setup>
import { Link } from '@inertiajs/inertia-vue3';
import { ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import { XMarkIcon } from '@heroicons/vue/20/solid';
import IconAvalanche from '../icons/IconAvalanche.vue';
import Dropdown from './Dropdown.vue';
import { NftApi } from '../Pages/Nfts/NftApi';
import { decimals, formatNumber, truncate } from '../composeables/filters.js';
import NoDataFound from '../load_templates/NoDataFound.vue';
import NftModal from '../load_templates/NftModal.vue';
import { PhotoIcon } from '@heroicons/vue/20/solid';
import SnowAnimation from '../Shared/SnowAnimation.vue';
import RainAnimation from '../Shared/RainAnimation.vue';
import PrivacyPolicyModal from './PrivacyPolicyModal.vue';
import TermsOfUseModal from './TermsOfUseModal.vue';
import AddYourProject from './AddYourProject.vue';
import _ from 'lodash';
const api = new NftApi();
const emit = defineEmits(['close-modal']);
const loading = ref(true);
const store = useStore();

const props = defineProps({
  showModal: Boolean,
  collection: String,
  collectionName: String,
  tokenId: String,
  modalType: { Type: String, default: '' },
});

function close() {
  emit('close-modal', { showModal: false });
}
const dropdownItems = ref([
  {
    id: 1,
    title: 'Joepegs',
    url: `https://joepegs.com/item/${props.collection}/${props.tokenId}/`,
    image: store.getters.marketplaceImageBaseLink + 'joepegs.png',
  },
  {
    id: 2,
    title: 'Campfire',
    url: `https://campfire.exchange/collections/${props.collection}/${props.tokenId}`,
    image: store.getters.marketplaceImageBaseLink + 'campfire.png',
  },
  {
    id: 3,
    title: 'Kalao',
    url: `https://marketplace.kalao.io/nft/${props.collection}_${props.tokenId}`,
    image: store.getters.marketplaceImageBaseLink + 'kalao.png',
  },
  {
    id: 4,
    title: 'NFTrade',
    url: `https://nftrade.com/assets/avalanche/${props.collection}/${props.tokenId}`,
    image: store.getters.marketplaceImageBaseLink + 'nftrade.png',
  },
  {
    id: 5,
    title: 'Opensea',
    url: `https://opensea.io/assets/avalanche/${props.collection}/${props.tokenId}`,
    image: store.getters.marketplaceImageBaseLink + 'opensea.png',
  },
]);

const marketplaceLists = computed(() => {
  if (dropdownItems.value && props.collectionName == 'Chikn') {
    dropdownItems.value.push({
      id: 6,
      title: 'Chikn',
      url: `https://chikn.farm/chikn/${props.tokenId}`,
      image: store.getters.marketplaceImageBaseLink + 'chikn.png',
    });
  }
  return dropdownItems.value;
});

const width = ref();
const height = ref();
const tks = ref({});
const position = computed(() => {
  return width.value < 640 || height.value < 750 ? 'top' : 'bottom';
});

const tier = ref('');
onMounted(async () => {
  tier.value = JSON.parse(localStorage.getItem('my_status'));
  tks.value = await fetchTks();
  loading.value = false;
  width.value = window.innerWidth;
  height.value = window.innerHeight;
  window.addEventListener('resize', () => {
    width.value = window.innerWidth;
    height.value = window.innerHeight;
  });
});
async function fetchTks() {
  return api.fetchTks(props.collection, { token_id: props.tokenId });
}
const traits_held_by_token = computed(() => {
  let traits = tks.value?.traits_held_by_token || [];
  const index = traits.indexOf('NULL');
  if (index > -1) {
    traits.splice(index, 1);
  }
  return traits;
});
function setupPrice(price) {
  price = parseFloat(price);
  if (price) {
    price = price * Math.pow(10, -18);
  }
  return price;
}
function selected(item) {
  window.open(item.url, '_blank');
}
</script>
<style scoped>
.snowfall {
  background-repeat: repeat-xy;
  opacity: 0.7;
}
</style>
