import axios from 'axios';
export class ChillFactorApi {
  async reqMethod(path, query = {}) {
    try {
      return [(await axios.get(path, { params: query })).data, null];
    } catch {
      return [null, 'Unknown error, please try again later.'];
    }
  }

  async fetchLeaderboardList() {
    const [res, error] = await this.reqMethod(`/chill_factor/leaderboard_list`);

    return error ? error : res;
  }

  async fetchCurrentSnapshot() {
    let address = localStorage.getItem('my_address')
      ? JSON.parse(localStorage.getItem('my_address'))
      : '';
    if (!address) return '';
    const [res, error] = await this.reqMethod(
      `/chill_factor/${address}/current_snapshot`,
    );

    return error ? error : res;
  }

  async fetchStatsHistoricalData() {
    let address = localStorage.getItem('my_address')
      ? JSON.parse(localStorage.getItem('my_address'))
      : '';
    if (!address) return '';
    const [res, error] = await this.reqMethod(
      `/chill_factor/${address}/stats_historical_data`,
    );

    return error ? error : res;
  }

  async uploadImage(formData) {
    try {
      return (await axios.post('/file_uploads', formData)).data;
    } catch {
      return null;
    }
  }
}
